import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkButton} from "@/components/button/button";

@customElement('sbk-ogs-abonnements-modal')
export class SbkOgsAbonnementsModal extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('#submit')
    _submitButton!: SbkButton

    protected createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        this._submitButton.addEventListener('click', () => {
            this._form.submit();
        })
    }
}
