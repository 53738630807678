import {customElement, property, state} from 'lit/decorators.js';
import {html, LitElement} from "lit";
import {tableCardStyle} from './table-card.styles';
import '@/components/icon/icon'
import '@/components/headline-link/headline-link'
import '@/components/indicator/indicator'
import './table-card.scss'

@customElement('sbk-table-card')
export class SbkTableCard extends LitElement {

    @property({type: String, attribute: 'indicator-variant'})
    indicatorVariant: 'info' | 'success' | 'note' | 'error' = 'info';

    @property({type: String, attribute: 'indicator-label'})
    indicatorLabel = '';

    @property({type: String})
    description?: string;

    @property({type: String, attribute: 'link-text'})
    linkText = '';

    @property({type: String})
    link = '';

    @property({type: String})
    icon = '';

    @property({type: String})
    variant: '' | 'white' = '';

    @state()
    has2ColumnSlot: boolean = false;

    static get styles() {
        return tableCardStyle;
    }

    render() {
        return html`
            <div part="table-card" role="row" class="table-content ${this.variant}">
                ${this.renderIcon()}
                <div class="content">
                    <div class="column-1" role="cell">
                        ${this.link === '' ?
                                html`<h3>${this.linkText}</h3>`
                                : html`
                                    <sbk-headline-link
                                            href=${this.link}
                                            variant="sm"
                                    >${this.linkText}</sbk-headline-link>`}
                        ${this.description ? html`<span class="small-text">${this.description}</span>` : ''}
                        ${this.renderIndicator()}
                        <slot name="column--1"></slot>
                    </div>
                    <div role="cell" ?hidden=${!this.has2ColumnSlot}>
                        <slot @slotchange=${this._on2ColumnSlotChange} name="column--2" class="column-2"></slot>
                    </div>
                    <div role="cell">
                        <slot name="column--3" class="column-3"></slot>
                    </div>
                </div>
            </div>
        `;
    }

    renderIcon() {
        if (!this.icon) {
            return '';
        }
        return html`
            <div class="icon" role="cell">
                <sbk-icon size="40" symbol=${this.icon}></sbk-icon>
            </div>
        `;
    }

    renderIndicator() {
        if (!this.indicatorLabel) {
            return '';
        }
        return html`
            <sbk-indicator label=${this.indicatorLabel} variant=${this.indicatorVariant}></sbk-indicator>
        `;
    }

    _on2ColumnSlotChange() {
        const slot: HTMLSlotElement | null = this.renderRoot.querySelector('slot[name=column--2]');
        if (!slot) {
            return ;
        }
        this.has2ColumnSlot = !!slot.assignedElements().length;
    }
}

