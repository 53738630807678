import { css, unsafeCSS } from 'lit';
import {
    sbkCoreBreakpointLg,
    sbkTableCardsSizingIconSizeTitle,
    sbkTableCardsSpacingSmallScreenGapIconTitle,
    sbkTableCardsSpacingLargeScreenGapIconTitle
} from '@/tokens/variables';

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const iconSize = unsafeCSS(`${sbkTableCardsSizingIconSizeTitle}`);
const iconColumnGapSmallScreen = unsafeCSS(`${sbkTableCardsSpacingSmallScreenGapIconTitle}`);
const iconColumnGapLargeScreen = unsafeCSS(`${sbkTableCardsSpacingLargeScreenGapIconTitle}`);

export const tableCardStyle = css`
    :host {
        position: relative;
    }

    * {
        box-sizing: border-box;
    }
    
    .table-content {
        display: flex;
        flex-direction: row;
        height: 100%;
        gap: var(--sbkTableCardsSpacingSmallScreenGapIconTitle);
        border-radius: var(--sbkTableCardsBorderBorderRadiusDefault);
        padding: var(--sbkTableCardsSpacingSmallScreenInsetTop) var(--sbkTableCardsSpacingSmallScreenInsetRight) var(--sbkTableCardsSpacingSmallScreenInsetBottom) var(--sbkTableCardsSpacingSmallScreenInsetLeft);
        font: var(--sbkTableCardsTypographySmallScreenBody);

        @media (min-width: ${breakpointLg}) {
            gap: var(--sbkTableCardsSpacingLargeScreenGapIconTitle);
            font: var(--sbkTableCardsTypographyLargeScreenBody);
            padding: var(--sbkTableCardsSpacingLargeScreenInsetTop) var(--sbkTableCardsSpacingLargeScreenInsetRight) var(--sbkTableCardsSpacingLargeScreenInsetBottom) var(--sbkTableCardsSpacingLargeScreenInsetLeft);
        }
    }

    .white {
        background-color: var(--sbkTableCardsColorBgColorEven);
    }

    .small-text {
        font: var(--sbkTableCardsTypographySmallScreenDescription);

        @media (min-width: ${breakpointLg}) {
            font: var(--sbkTableCardsTypographyLargeScreenDescription);
        }
    }
    

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--sbkTableCardsSpacingSmallScreenGapColumns);

        @media (min-width: ${breakpointLg}) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .column-1, .column-2, .column-3 {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: var(--sbkTableCardsSpacingSmallScreenGapFirstColumn);

        @media (min-width: ${breakpointLg}) {
            gap: var(--sbkTableCardsSpacingLargeScreenGapFirstColumn);
        }
    }

    .column-1 {
        flex: 2;
    }


    .icon {
        max-width: calc(${iconSize} + ${iconColumnGapSmallScreen});

        @media (min-width: ${breakpointLg}) {
            flex: 0 0 calc(${iconSize} + ${iconColumnGapLargeScreen});
        }
    }

    h3 {
        margin: 0;
    }

    sbk-button {
        margin-top: var(--sbkTableCardsSpacingLargeScreenMarginTopButton);
    }
`;

