import {LitElement, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {androidSmartAppBannerStyles} from "@/components/android-smart-app-banner/android-smart-app-banner.styles.ts";
import './android-smart-app-banner.scss'

// eslint-disable-next-line
declare const TYPO3: any;

@customElement('sbk-android-smart-app-banner')
export class SbkAndroidSmartAppBanner extends LitElement {

    @state()
    androidSmartAppBannerLink = '';

    @state()
    appName = '';

    @state()
    appHint = '';

    @state()
    linkText = '';

    @property()
    environment = '';

    @property({type: String, attribute: 'logo-path'})
    logoPath = '';

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback()
        window.addEventListener('load', this.checkForRelatedApps);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('load', this.checkForRelatedApps);
    }

    static get styles() {
        return [androidSmartAppBannerStyles];
    }

    render() {
        if (!this.androidSmartAppBannerLink) {
            return nothing;
        }
        return html`
            <div id="android-smart-app-banner" class="android-smart-app-banner">
                <img src=${this.logoPath} class="android-smart-app-banner__logo" alt="" />
                <div class="android-smart-app-banner__text">
                    <strong id="app-name">${this.appName}</strong>
                    <p id="app-hint">${this.appHint}</p>
                </div>
                <div>
                    <sbk-button 
                       href=${this.androidSmartAppBannerLink}
                       >
                       ${this.linkText}
                    </sbk-button>
                </div>
            </div>
        `;
    }

    checkForRelatedApps = () => {
        // eslint-disable-next-line
        const nav: any = window.navigator
        const isMobile = nav.userAgentData.mobile
        const platform = nav.userAgentData.platform
        // Check to see if the API is supported.
        if ('getInstalledRelatedApps' in nav && isMobile && platform === 'Android') {
            const path = document.location.pathname + document.location.search
            const environment = this.environment.toLowerCase()
            const position = environment.search('/')

            let env;
            if (position) {
                env = environment.slice(position + 1)
            } else {
                env = environment
            }
            // eslint-disable-next-line
            nav.getInstalledRelatedApps().then((relatedApps: any) => {
                // eslint-disable-next-line
                const hasSBKLiveApp = relatedApps.find((app: any) => app.id === `de.sbk.meinesbk`)
                // eslint-disable-next-line
                const hasSBKDevApp = relatedApps.find((app: any) => app.id === `de.sbk.meinesbk.${env}`)

                if (hasSBKDevApp) {
                    this.androidSmartAppBannerLink = `intent://${env}-meine.sbk.org${path}#Intent;scheme=https;package=de.sbk.meinesbk.${env};end`;
                    this.appName = `${env} ${TYPO3.lang['AndroidSmartAppBannerAppTitle']}`
                    this.appHint = `${TYPO3.lang['AndroidSmartAppBannerAppTeaserOpen']}`
                    this.linkText = `${TYPO3.lang['AndroidSmartAppBannerOpen']}`
                } else if (hasSBKLiveApp) {
                    this.androidSmartAppBannerLink = `intent://meine.sbk.org${path}#Intent;scheme=https;package=de.sbk.meinesbk;end`;
                    this.appName = `${TYPO3.lang['AndroidSmartAppBannerAppTitle']}`
                    this.appHint = `${TYPO3.lang['AndroidSmartAppBannerAppTeaserOpen']}`
                    this.linkText = `${TYPO3.lang['AndroidSmartAppBannerOpen']}`
                } else {
                    this.androidSmartAppBannerLink = `intent://meine.sbk.org${path}#Intent;scheme=https;package=de.sbk.meinesbk;end`;
                    this.appName = `${TYPO3.lang['AndroidSmartAppBannerAppTitle']}`
                    this.appHint = `${TYPO3.lang['AndroidSmartAppBannerAppTeaserDownload']}`
                    this.linkText = `${TYPO3.lang['AndroidSmartAppBannerLoad']}`
                }

            });
            this.androidSmartAppBannerLink = `intent://meine.sbk.org${path}#Intent;scheme=https;package=de.sbk.meinesbk;end`;
            this.appName = `${TYPO3.lang['AndroidSmartAppBannerAppTitle']}`
            this.appHint = `${TYPO3.lang['AndroidSmartAppBannerAppTeaserDownload']}`
            this.linkText = `${TYPO3.lang['AndroidSmartAppBannerLoad']}`
        }
    }
}
