import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkToggle} from "@/components/toggle/toggle";
import {query} from "lit/decorators/query.js";

@customElement('sbk-toggle-submit-form')
export class SbkToggleSubmitForm extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('sbk-toggle')
    _toggle!: SbkToggle

    protected createRenderRoot() {
        return this
    }

    connectedCallback() {
        super.connectedCallback();
        this._toggle?.addEventListener('toggle-change', () => {
            this._form.submit();
        })
    }
}