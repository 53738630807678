import {customElement} from 'lit/decorators.js';
import {LitElement} from "lit";
import {query} from "lit/decorators/query.js";
import {SbkSelect} from "@/components/select/select";

@customElement('sbk-select-submit-form')
export class SbkSelectSubmitForm extends LitElement {

    @query('form')
    _form!: HTMLFormElement

    @query('sbk-select')
    _select!: SbkSelect

    protected createRenderRoot() {
        return this
    }

    async connectedCallback() {
        super.connectedCallback();
        if (this._select) {
            await (<LitElement>this._select).updateComplete;
            this._select.addEventListener('select-value-changed', (e) => {
                e.preventDefault();
                this._form.submit();
            });
        }
    }
}