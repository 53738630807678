import {css} from 'lit';
import {buttonOnDarkStyles} from "@/components/button/button--onDark.styles.ts";

export const androidSmartAppBannerStyles = css`
    :host {
        ${buttonOnDarkStyles}
    }
    #app-name {
        color: var(--sbkBannerBoxColorBgColorNeutral);
    }

    #app-hint {
        color: var(--sbkBannerBoxColorBgColorNeutral);
        font: var(--sbkTypographyContentSmallScrBodyRegularXs);
        margin: 0;
    }

    .android-smart-app-banner {
        display: flex;
        gap: var(--sbkCoreSpacing2xs);
        justify-content: space-between;
        align-items: center;
        padding: var(--sbkCoreSpacing2xs);
        background-color: var(--sbkBannerBoxColorBgColorEmphasised);

        .android-smart-app-banner__logo {
            width: var(--sbkCoreSpacingMd);
            height: var(--sbkCoreSpacingMd);
        }

        .android-smart-app-banner__link-container {
            background-color: var(--sbkBannerBoxColorBgColorNeutral);
            border-radius: var(--sbkCoreSpacingSm);
            padding: var(--sbkCoreSpacing3xs) var(--sbkCoreSpacing2xs);
        }

        .android-smart-app-banner__link {
            color: var(--sbkBannerBoxColorBgColorEmphasised);
        }

    }
`;