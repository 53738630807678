import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkCheckbox} from "@/components/checkbox/checkbox";
import {SbkButton} from "@/components/button/button";
import './mailbox.scss'
import {SbkModal} from "@/components/modal/modal";
import {SbkRoundButton} from "@/components/round-button/round-button";
import {SbkIcon} from "@/components/icon/icon";

@customElement('sbk-mailbox')
export class SbkMailbox extends LitElement {

    @query('#selectAll')
    _selectAll!: SbkCheckbox

    @query('#deleteAllFooter')
    _selectAllFooter!: SbkCheckbox

    @query('#deleteDocuments')
    _deleteSelectedRowsButton!: SbkButton

    protected createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        const initialSubmitValue = this._deleteSelectedRowsButton?.value ? this._deleteSelectedRowsButton.value : '';
        this._selectAll.addEventListener('click', () => {
            this.handleSelectAllClick(this._selectAll.checked, initialSubmitValue)
        })
        this._deleteSelectedRowsButton.addEventListener('click', this.showModalDeleteSelectedDocuments)
        this._selectAllFooter.addEventListener('click', () => {
            this.handleSelectAllClick(this._selectAllFooter.checked, initialSubmitValue)
        })
        this.initializeItemCheckboxes(initialSubmitValue);
        this.initializeMailboxDownload();
    }

    handleSelectAllClick(checked: boolean, initialSubmitValue: string) {
        const checkboxes: SbkCheckbox[] = Array.from(document.querySelectorAll(`.mailbox__select-document`))
        const enabledSingleCheckboxes: SbkCheckbox[] = checkboxes.filter((checkbox: SbkCheckbox) => !checkbox.readonly)

        if (!checked) {
            enabledSingleCheckboxes.forEach((checkbox: SbkCheckbox) => {
                checkbox.checked = true
            })
            this.updateDeleteSelectedDocumentsButton(initialSubmitValue, enabledSingleCheckboxes.length)
        } else {
            enabledSingleCheckboxes.forEach((checkbox: SbkCheckbox) => {
                checkbox.checked = false
            })
            this.updateDeleteSelectedDocumentsButton(initialSubmitValue, 0)
        }
    }

    updateDeleteSelectedDocumentsButton(initialSubmitValue: string, amountOfSelectedCheckboxes: number) {
        if (amountOfSelectedCheckboxes === 0) {
            this.disableDeleteSelectedDocumentsButton(initialSubmitValue)
        } else {
            this.enableDeleteSelectedDocumentsButton(initialSubmitValue, amountOfSelectedCheckboxes)
        }
    }


    enableDeleteSelectedDocumentsButton(initialSubmitValue: string, amountOfSelectedCheckboxes: number) {
        const updatedValue: string = `${initialSubmitValue} (${amountOfSelectedCheckboxes})`

        this._deleteSelectedRowsButton.innerHTML = updatedValue
        this._deleteSelectedRowsButton.value = updatedValue
        this._deleteSelectedRowsButton.disabled = false;
    }

    disableDeleteSelectedDocumentsButton(initialSubmitValue: string) {
        const updatedValue: string = `${initialSubmitValue}`

        this._deleteSelectedRowsButton.innerHTML = updatedValue
        this._deleteSelectedRowsButton.value = updatedValue
        this._deleteSelectedRowsButton.disabled = true;
    }

    initializeItemCheckboxes(initialSubmitValue: string) {
        const multipleDeleteCheckboxes: SbkCheckbox[] = Array.from(document.querySelectorAll(`.mailbox__select-document`))

        multipleDeleteCheckboxes.forEach((multipleDeleteCheckbox: SbkCheckbox) => {
            multipleDeleteCheckbox.addEventListener('checkbox-change', () => {
                if (!multipleDeleteCheckbox.readonly) {
                    const checkedCheckboxes: SbkCheckbox[] = multipleDeleteCheckboxes.filter(
                        (checkbox: SbkCheckbox) => {
                            return checkbox.checked
                        }
                    )
                    this.updateDeleteSelectedDocumentsButton(initialSubmitValue, checkedCheckboxes.length)
                }
            })
            multipleDeleteCheckbox.addEventListener('click', (event) => {
                if (multipleDeleteCheckbox.readonly) {
                    event.preventDefault();
                    this.showModalDeleteNotPossible()
                }
            })
        })
    }

    showModalDeleteNotPossible() {
        const modal: SbkModal | null = document.querySelector('#deleteNotPossibleModal')
        if (modal) {
            modal.showModal()
        }
    }

    showModalDeleteSelectedDocuments = () => {
        const modal: SbkModal | null = this.querySelector('#deleteDocumentsModal')
        if (modal) {
            //show number of checked documents
            const multipleDeleteCheckboxes: SbkCheckbox[] = Array.from(
                this.querySelectorAll(`.mailbox__select-document`)
            )
            const checkedCheckboxes: SbkCheckbox[] = multipleDeleteCheckboxes.filter((checkbox: SbkCheckbox) => {
                return checkbox.checked
            })

            const documentCount: number = checkedCheckboxes.length

            if (documentCount <= 1) {
                const tableRow: Element | null | undefined = checkedCheckboxes[0].closest(`.mailbox__row`)
                if (!tableRow) {
                    return;
                }
                const modalDeleteSingleDocument: SbkModal[] = Array.from(tableRow.querySelectorAll('[id^="deleteDocumentModal"]'));
                modalDeleteSingleDocument.forEach(modal => {
                    if (modal.offsetParent !== null) {
                        modal.showModal();
                    }
                })
            } else {
                const modalContent: HTMLDivElement | null | undefined = modal.shadowRoot?.querySelector('.modal__content')
                const contentSlot = modalContent?.querySelector('slot') as HTMLSlotElement;
                 
                contentSlot?.assignedElements({flatten: true}).forEach(contentElement => {
                    if (contentElement instanceof HTMLParagraphElement) {
                        const numberOfDocuments: HTMLSpanElement | null = contentElement.querySelector('.number-of-documents');
                        if (numberOfDocuments)
                            numberOfDocuments.innerHTML = documentCount.toString()
                    }
                })

                // Submit form
                const deletionForm: HTMLFormElement | null = this.querySelector('#documentDeleteForm')
                const footerSlot = modal.shadowRoot?.querySelector('slot[name="footer"]') as HTMLSlotElement;
                 
                footerSlot?.assignedElements({flatten: true}).forEach(button => {
                    if ('confirmMultipleDelete' === button.id && button instanceof SbkButton) {
                        button.addEventListener('click', () => {
                            deletionForm?.submit()
                        })
                    }
                });
                modal.showModal()
            }
        }
    }

    initializeMailboxDownload() {

        const downloadButtons: SbkRoundButton[] = Array.from(document.querySelectorAll(`.documentDownloadButton`))

        const handleDownloadClick = (downloadButton: SbkRoundButton) => {
            const tableRow: Element | null | undefined = downloadButton.closest(`.mailbox__row`)
            const subjectTitle: Element | null | undefined = tableRow?.querySelector('.mailbox__column')

            if (subjectTitle) {
                subjectTitle.classList.remove(`unread`)
                subjectTitle.classList.add(`read`)
            }

            const deleteButtons = tableRow?.querySelectorAll('.deleteDocumentButton')
            if (deleteButtons) {
                deleteButtons.forEach((deleteButton) => {
                    deleteButton.classList.remove(`hidden`)
                })
            }

            const checkbox: SbkCheckbox | null | undefined = tableRow?.querySelector('.mailbox__select-document')
            if (checkbox) {
                checkbox.readonly = false
            }

            const multipleDeleteCheckbox: SbkCheckbox | null = document.querySelector(`.mailbox__select-column`)

            if (multipleDeleteCheckbox) {
                multipleDeleteCheckbox.classList.remove(`disabled`)
            }

            const icon: SbkIcon | null | undefined = tableRow?.querySelector('sbk-icon')
            if (icon) {
                icon.symbol = 'open-envelope'
            }
        }

        downloadButtons.forEach((downloadButton: SbkRoundButton) => {
            downloadButton.addEventListener('click', () => {
                handleDownloadClick(downloadButton)
            })
        })
    }
}
