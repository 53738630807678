import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {query} from "lit/decorators/query.js";
import {SbkButton} from "@/components/button/button";
import {SbkInput} from "@/components/input/input";


/**
 * An example element.
 *
 * @slot - This element has a slot
 */
@customElement('sbk-form-submit-toggle')
export class SbkFormSubmitToggle extends LitElement {

    @query('#submit')
    _submitButton!: SbkButton

    protected createRenderRoot() {
        return this;
    }

    firstUpdated() {
        if (this._submitButton) {
            this._submitButton.disabled = true;
            const form: HTMLFormElement | null | undefined = this.closest('form');
            if (form) {
                const inputs: SbkInput[] | null = Array.from(form.querySelectorAll('sbk-input'));
                if (inputs) {
                    this._enableOnSubmitOnChange(inputs)
                }
            }
        }
    }

    _enableOnSubmitOnChange(inputs: SbkInput[]) {
        inputs.forEach((input: SbkInput) => {
            input.addEventListener('input-change', () => {
                this._submitButton.disabled = false;
            })
        })
    }
}
