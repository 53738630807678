import {LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import "@/components/form/form.scss"
import {query} from "lit/decorators/query.js";
import {SbkInput} from "@/components/input/input";
import {SbkRadio} from "@/components/radio/radio";

@customElement('sbk-bank-details')
export class SbkBankDetails extends LitElement {

    @query('#iban')
    _iban!: SbkInput

    @query('#bic')
    _bic!: SbkInput

    @query('#kreditinstitut')
    _kreditinstitut!: SbkInput

    connectedCallback() {
        super.connectedCallback();
        // fix android copy paste issue
        this._iban?.addEventListener('paste', (event) => {
            const iban = (event as ClipboardEvent)?.clipboardData?.getData("text")
            if (iban)
                this.ibanCompletionBankverbindung(iban)
        })
        this._iban?.addEventListener('input-keyup', (event) => {
            this.ibanCompletionBankverbindung((event as CustomEvent).detail.value)
        })
        this.initializeBankdetailsmms()
    }

    protected createRenderRoot() {
        return this
    }

    async ibanCompletionBankverbindung(value: string) {
        const ibanValue = value.replace(/\s/g, '');

        if (ibanValue.length >= 22) {
            const host = window.location.origin

            await fetch(`${host}/ajax/ogs/bankDetailsFromIban?iban=${ibanValue}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then((response) => {
                    return response.text()
                })
                .then((data) => {
                    const jsonData = JSON.parse(data);
                    if (this._bic) {
                        this._bic.value = jsonData.bic
                    }

                    if (this._kreditinstitut) {
                        this._kreditinstitut.value = jsonData.kreditinstitut
                    }
                })
        }
    }

    initializeBankdetailsmms() {
        const anotherAccountIban: HTMLInputElement | null = this.querySelector('[name*="anotherAccountIban]"]')
        const anotherAccountFirstname: HTMLInputElement | null = this.querySelector(
            '[name*="anotherAccountFirstname]"]'
        )
        const anotherAccountLastname: HTMLInputElement | null = this.querySelector(
            '[name*="anotherAccountLastname]"]'
        )
        const firstRadioOption: SbkRadio | null = this.querySelector('sbk-radio[id*="-radio-0"]')
        const secondRadioOption: SbkRadio | null = this.querySelector('sbk-radio[id*="-radio-1"]')
        const permanentAccountYesRadioOption: SbkRadio | null = this.querySelector('sbk-radio[id*="-permanent-yes"]')
        const permanentAccountNoRadioOption: SbkRadio | null = this.querySelector('sbk-radio[id*="-permanent-no"]')

        const hiddenFields: HTMLInputElement[] = Array.from(this.querySelectorAll('input[type="hidden"]'))
        const firstHiddenField: HTMLInputElement = hiddenFields[0]
        const submittedBankdetailsValue: string = firstHiddenField.value

        if (submittedBankdetailsValue.toLowerCase().indexOf('requirederror###') >= 0) {
            const partsRequiredError = submittedBankdetailsValue.split('###|')

            if (secondRadioOption) {
                this.toggleRadioButton(secondRadioOption, true)
            }
            if (anotherAccountIban)
                anotherAccountIban.value = partsRequiredError[1]
            if (anotherAccountFirstname)
                anotherAccountFirstname.value = partsRequiredError[2]
            if (anotherAccountLastname)
                anotherAccountLastname.value = partsRequiredError[3].replace(',', '')

        } else {
            if (typeof submittedBankdetailsValue !== 'undefined' && submittedBankdetailsValue !== '') {
                if (submittedBankdetailsValue.indexOf('****') >= 0) {
                    if (firstRadioOption)
                        this.toggleRadioButton(firstRadioOption, true)
                } else {
                    const parts: string[] = submittedBankdetailsValue.split(' (')
                    const iban: string = parts[0]

                    const ownerPart = typeof parts[1] !== 'undefined' ? parts[1].split(': ') : ['', '']
                    const owner = ownerPart[1].split(')')[0]
                    const ownerFirstLastName = owner.split(',')

                    // split firstname and lastname
                    const ownerFirstname = typeof ownerFirstLastName[1] !== 'undefined' ? ownerFirstLastName[1].trim() : ''
                    const ownerLastname = typeof ownerFirstLastName[0] !== 'undefined' ? ownerFirstLastName[0].trim() : ''

                    if (iban === '') {
                        if (firstRadioOption)
                            this.toggleRadioButton(firstRadioOption, false)
                        if (secondRadioOption)
                            this.toggleRadioButton(secondRadioOption, false)
                    } else if (
                        submittedBankdetailsValue.indexOf(':  ') === -1 &&
                        submittedBankdetailsValue.toLowerCase().indexOf('requirederror###') === -1
                    ) {
                        if (firstRadioOption)
                            this.toggleRadioButton(firstRadioOption, true)
                    } else {
                        if (secondRadioOption)
                            this.toggleRadioButton(secondRadioOption, true)

                        if (iban.toLowerCase().indexOf('requirederror###') === -1) {
                            if (anotherAccountIban)
                                anotherAccountIban.value = iban
                        } else {
                            if (anotherAccountIban)
                                anotherAccountIban.value = ''
                        }

                        if (ownerFirstname && anotherAccountFirstname) {
                            anotherAccountFirstname.value = ownerFirstname
                        }

                        if (ownerLastname && anotherAccountLastname) {
                            anotherAccountLastname.value = ownerLastname.replace(',', '')
                        }

                        if (permanentAccountYesRadioOption && permanentAccountNoRadioOption) {
                            const storePermanentParts: string[] = submittedBankdetailsValue.split('?')
                            const storePermanent = typeof storePermanentParts[1] !== 'undefined' ? storePermanentParts[1].trim() : ''
                            if (storePermanent === 'Ja') {
                                this.toggleRadioButton(permanentAccountYesRadioOption, true)
                            } else if (storePermanent === 'Nein') {
                                this.toggleRadioButton(permanentAccountNoRadioOption, true)
                            }
                        }
                    }
                }
            }
        }
    }

    toggleRadioButton(radiButton: SbkRadio, checkedState: boolean) {
        if (radiButton) radiButton.checked = checkedState
    }
}
