import {customElement} from 'lit/decorators.js';
import {html, LitElement} from "lit";
import './table-cards-container.scss'

@customElement('sbk-table-cards-container')
export class SbkTableCardsContainer extends LitElement {
    render() {
        return html`
            <div role="table">
                <slot></slot>
            </div>
        `;
    }
}
