import {customElement, query} from 'lit/decorators.js';
import {LitElement} from "lit";
import {SbkButton} from "@/components/button/button";
import {SbkModal} from "@/components/modal/modal";

@customElement('sbk-generaleinwilligung')
export class SbkOgsAbonnementsModal extends LitElement {

    @query('.einwGeneralLayer')
    _modalButton!: SbkButton

    @query('sbk-modal')
    _modal!: SbkModal

    protected createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();
        this._modalButton?.addEventListener('click', (event: Event) => {
            this.showModal(event, this._modal)
        })
    }

    showModal(e: Event, modal: SbkModal) {
        e.preventDefault();
        modal._showModal(e);
    }
}
