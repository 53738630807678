// Vendor
import 'vite/modulepreload-polyfill'

import '@/styles/styles.scss'
import '@/styles/org-page-layout.scss'
import '@/styles/ogs-page-layout.scss'
import '@/styles/ogs-login-page-layout.scss'

// Components
import '@/components/android-smart-app-banner/android-smart-app-banner'
import '@/components/alert/alert'
import '@/components/button/button'
import '@/components/button-group/button-group.scss'
import '@/components/icon/icon'
import '@/components/round-button/round-button'
import '@/components/accordion/accordion'
import '@/components/banner/banner'
import '@/components/badge/badge'
import '@/components/bonus-card/bonus-card.scss'
import '@/components/checkbox/checkbox'
import '@/components/checkbox-group/checkbox-group'
import '@/components/contact-data/contact-data.scss'
import '@/components/data-protection/data-protection'
import '@/components/divider/divider'
import '@/components/summary-page/summary-page'
import '@/components/form/form'
import '@/components/form-control-dependency/form-control-dependency'
import '@/components/form-steps/form-steps'
import '@/components/form-step-item/form-step-item'
import '@/components/image-sources/image-sources'
import '@/components/icon-headline/icon-headline.scss'
import '@/components/input/input'
import '@/components/headline-link/headline-link'
import '@/components/image/image'
import '@/components/indicator/indicator'
import '@/components/link/link'
import '@/components/list/list'
import '@/components/loading-animation/loading-animation'
import '@/components/modal/modal'
import '@/components/pagination/pagination'
import '@/components/pagination-item/pagination-item'
import '@/components/personal-advising/personal-advising'
import '@/components/radio/radio'
import '@/components/radio-group/radio-group'
import '@/components/select/select'
import '@/components/select-option/select-option'
import '@/components/select-option-tag/select-option-tag'
import '@/components/tab-menu/tab-menu'
import '@/components/tab-menu-item/tab-menu-item'
import '@/components/teaser/teaser'
import '@/components/teaser-container/teaser-container'
import '@/components/telephone-number-input/telephone-number-input'
import '@/components/textarea/textarea'
import '@/components/table/table'
import '@/components/annotation/annotation'
import '@/components/toggle/toggle'
import '@/components/address-input/address-input'
import '@/components/datepicker/datepicker'
import '@/components/search-filter-nav/search-filter-nav'
import '@/components/search-result/search-result'
import '@/components/search-info/search-info'
import '@/components/citation-teaser/citation-teaser'
import '@/components/floating-button/floating-button'
import '@/components/teaser-link/teaser-link'
import '@/components/tag/tag'
import '@/components/copy-link/copy-link'
import '@/components/tag-list/tag-list'
import '@/components/repeatable-container/repeatable-container'
import '@/components/file-upload/fileupload'
import '@/components/matomo/language-switch-tracking'
import '@/components/matomo/search-teaser-tracking'
import '@/components/cookie-overlay/cookie-overlay'
import '@/components/generaleinwilligung/generaleinwilligung'
import '@/components/label/label'
import '@/components/toggle-submit-form/toggle-submit-form'
import '@/components/ogs-abonnements-modal/ogs-abonnements-modal'
import '@/components/bankdetails/bankdetails'
import '@/components/form-submit-toggle/form-submit-toggle'
import '@/components/mailbox/mailbox'
import '@/components/opq/opq.scss'
import '@/components/table-card/table-card'
import '@/components/table-cards-container/table-cards-container'
import '@/components/filter-sort-list-layout/filter-sort-list-layout'
import '@/components/select-submit-form/select-submit-form'
import "@lottiefiles/lottie-player";
import '@/components/callbackform/callbackform'

// init cookie layer
import '@/components/cookie-layer/cookie-layer.scss'
import CookieLayer from '@/components/cookie-layer/cookie-layer'

CookieLayer.initialize()
